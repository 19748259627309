<template>
    <v-row>
        <v-col :cols="filterColumnCount" v-if="filtersVisible">
            <base-card>
                <!-- <v-card-title>
                    <span class="font-weight-bold">Filters</span>
                </v-card-title> -->
                <v-card-text>

                    <div class="d-flex justify-center pb-2 px-3">   
                        <img class="ma-4 mx-0" style="display: inline-block" src="/icon-small.jpg" height="34" />
                        <h5 class="ma-5 ml-2" style="display: inline-block" >Adpoints</h5> 
                    </div>                            
                     
                    <!-- <v-btn class="d-flex mb-2" style="width: 100%;" small color="secondary" outlined>
                        Filtering Rule
                    </v-btn> -->
                    <v-btn class="d-flex" style="width: 100%;" small color="primary" outlined @click="onSearch">
                        Apply
                    </v-btn>        
                    <!-- FF: {{JSON.stringify(filterfields)}}           -->
                    <!-- <advanced-grid-filters-list :dynamic="true" :fields="filterfields" v-model:filtervalue="filtercriteria" @search="onSearch" :searchButtonVisible="false" @delete="onDeleteCondition" /> -->                    
                    <advanced-grid-filters-list 
                        :dynamic="true" 
                        v-model="filterfields" 
                        @search="onSearch" 
                        :searchButtonVisible="false" 
                        @delete="onDeleteCondition" 
                        @deleteor="onDeleteOrCondition" 
                        ref="advancedfilter" 
                        @addcriteria="addCondition" 
                        :attributeOptions="attributeOptions" 
                    />
                    <!-- <v-autocomplete
                        
                        v-model="attributeToAdd"
                        :items="attributeOptions"
                        label="Attribute"      
                        outlined       
                        dense                                                      
                    ></v-autocomplete>
                    <v-btn class="d-flex" style="width: 100%;" small color="primary" outlined @click="addCondition" >
                        Add Attribute Condition
                    </v-btn> -->
                    <v-btn class="d-flex mt-2" style="width: 100%;" small color="black" dark @click="addOrClause" >
                        Add "OR" Cond.
                    </v-btn>              
                </v-card-text>                          
            </base-card>            
        </v-col>
        <v-col :cols="gridColumnCount">
            <base-card>
                <v-card-title>                                                        
                    <div class="d-flex justify-space-between flex">
                        <!-- <h5 class="ma-5 mb-0 d-flex flex">Adpoints</h5>-->
                        <v-autocomplete
                            class="ma-4 mb-0 d-flex flex"
                            v-model="selectedNetwork"
                            :items="networkOptions"
                            label="Network"      
                            outlined       
                            dense                                                      
                        ></v-autocomplete>
                        <v-autocomplete
                            class="ma-4 mb-0 d-flex flex"
                            v-model="selectedCalcInterval"
                            :items="['Custom Date Range','Last 30 Days','Last 20 Days','Last 15 Days','Last 10 Days','Last 7 Days','Last 5 Days','Last 3 Days', 'Next 3 Days', 'Next 5 Days', 'Next 7 Days', 'Next 10 Days', 'Next 15 Days', 'Next 20 Days', 'Next 30 Days']"
                            label="Available Capacity Based-On"      
                            outlined       
                            dense                                                      
                        ></v-autocomplete>
                        <!-- <v-autocomplete
                            class="ma-4 mb-0 d-flex flex"
                            v-model="selectedNetwork"
                            :items="networkOptions"
                            label="Network"      
                            outlined       
                            dense                                                      
                        ></v-autocomplete> -->
                        <v-text-field
                                v-if="selectedCalcInterval != 'Custom Date Range'"
                                v-model="quicksearch"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details                                                               
                                @input="triggerQuicksearch"
                        ></v-text-field>


                        <v-menu
                            v-if="selectedCalcInterval === 'Custom Date Range'"
                            ref="calcDatepicker"
                            v-model="calculationDatepicker"
                            :close-on-content-click="false"
                            
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{on, attrs}">
                                <v-text-field
                                class="ma-4 mb-0 d-flex flex"
                                    label='Select Date Range'
                                    dense
                                    outlined
                                    v-model="calculationDate"                                              
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details    
                                    :clearable="true"                                                       
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="calculationDate" scrollable range>                    
                            </v-date-picker>
                        </v-menu>


                        <v-btn class="ma-2" dark color="primary" @click="onSearch">
                                <v-icon>mdi-reload</v-icon>
                                Reload
                        </v-btn>
                        <!-- <v-btn class="ma-2" dark>
                            <v-icon>mdi-keyboard_arrow_down</v-icon>
                            Actions
                        </v-btn> -->


                        <!-- <v-btn class="ma-2" dark color="danger" to="/mng/adpoints/management/create">
                            <v-icon>mdi-plus</v-icon>
                            Create
                        </v-btn>-->
                    </div>
                </v-card-title>                                        
            
            </base-card>
            <v-row>
                <v-col cols="4" >
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    Total
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >{{totalItemCount}}</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <span class="font-weight-bold text-info"
                                            >Adpoints</span
                                        >
                                    </div>
                                </v-list-item-subtitle>
                                <!-- <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <v-sheet
                                            height="20"
                                            width="20"
                                            class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                                        >
                                            <v-icon class="info--text caption"
                                                >mdi-arrow-top-right</v-icon
                                            >
                                        </v-sheet>
                                        <span class="font-weight-bold text-info"
                                            >14% Inc</span
                                        >
                                    </div>
                                </v-list-item-subtitle> -->
                            </v-list-item-content>

                            <!-- <apexchart
                                width="100"
                                height="150"
                                type="radialBar"
                                :options="chartOptions"
                                :series="series"
                            /> -->
                        </v-list-item>
                    </base-card>
                </v-col>
                <v-col cols="4" >
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    Available Capacity
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >{{formatInteger((totalItemCount*2880).toString())}} Impressions</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <!-- <v-sheet
                                            height="20"
                                            width="20"
                                            class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                                        >
                                            <v-icon class="info--text caption"
                                                >mdi-arrow-top-right</v-icon
                                            >
                                        </v-sheet> -->
                                        <span class="font-weight-bold text-info"
                                            >Out of {{formatInteger((totalItemCount*2880).toString())}}</span
                                        >
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <apexchart
                                width="80"
                                height="110"
                                type="radialBar"
                                :options="chartOptions"
                                :series="series"
                            />
                        </v-list-item>
                    </base-card>
                </v-col>
                <v-col cols="4" >
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    Activity
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >{{formatInteger('0')}} Events</v-list-item-title
                                >
                                <!-- <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <v-sheet
                                            height="18"
                                            width="18"
                                            class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                                        >
                                            <v-icon class="info--text caption"
                                                >mdi-arrow-top-right</v-icon
                                            >
                                        </v-sheet>
                                        <span class="font-weight-bold text-info"
                                            >14% Inc</span
                                        >
                                    </div>
                                </v-list-item-subtitle> -->
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <!-- <v-sheet
                                            height="18"
                                            width="18"
                                            class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                                        >
                                            <v-icon class="info--text caption"
                                                >mdi-arrow-top-right</v-icon
                                            >
                                        </v-sheet> -->
                                        <span class="font-weight-bold text-info"
                                            >Total of selected period</span
                                        >
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <!-- <apexchart
                                width="80"
                                height="110"
                                type="radialBar"
                                :options="chartOptions2"
                                :series="series2"
                            /> -->
                        </v-list-item>
                    </base-card>
                </v-col>
                <!-- <v-col cols="3" >
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>                                
                                <div class="body-2 mb-0 text--disabled">
                                    Campaigns
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >0</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <base-hover-button
                                            textColor="primary--text"
                                            bg-color="primary lighten-5"
                                            class="mr-2"
                                            text="View Campaigns"
                                            x-small
                                        ></base-hover-button>
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </base-card>
                </v-col> -->
            </v-row>
            
            
            <base-card>
                <!-- <v-card-title class="pb-0">
                    Users
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    
                </v-card-title> -->    
                <!-- <v-card-title class="d-flex justify-space-between pb-0 pt-1" style="position: absolute; right: 0px;">                    
                    <v-menu offset-y>
                        <template v-slot:activator="{on}">
                            <v-btn icon v-on="on">
                                <v-icon color="primary"
                                    >mdi-dots-horizontal</v-icon
                                >
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item>
                                <v-btn class="ma-2" to="/mng/adpoints/management/create">
                                    <v-icon>mdi-plus</v-icon>
                                    Create Adpoint
                                </v-btn>
                            </v-list-item>                            
                        </v-list>
                    </v-menu>
                </v-card-title>                             -->
                <v-data-table
                    v-model="selected"                    
                    :headers="headers"
                    :options.sync="pagingOptions"
                    :server-items-length="totalItemCount"
                    :items="gridData"
                    item-key="adpointid"
                    show-select                    
                    class="table-one"
                    multi-sort
                >             
                    <template v-slot:[`header.data-table-select`]></template>       
                    <template v-slot:item.fullname="{item}">
                        <div class="d-flex align-center">
                            <v-avatar class="mr-2" size="26" dark>
                                <img src="@/assets/images/avatars/001-man.svg" alt="" />
                            </v-avatar>
                            <p class="ma-0 font-weight-medium">
                                {{ item.fullname }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:item.aud_create_date="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ item.aud_create_date | formatDate }}
                            </p>
                        </div>
                    </template>
                    

                    <template v-slot:item.action="{item}">
                        <div class="d-flex">
                            <!-- <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        :to="'/mng/adpoints/management/edit/' + item.id"
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip> -->
                            
                            <!-- <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip> -->
                        </div>
                    </template>
                    <template v-slot:item.status="{item}">
                        <template v-if="item.status != 'offline'">
                            <v-chip
                                class=""    
                                color="success"                            
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.status }}
                            </v-chip>
                        </template>                        
                        <template v-else>
                            <v-chip
                                class=""
                                color="error"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </template>
                </v-data-table>
            </base-card>

            <!-- <base-card class="mt-3">
                <div class="d-flex align-center justify-space-between pr-3">
                    <v-card-title class="pb-0">Daily Activity Heatmap</v-card-title>
                    <div>
                    <v-menu left bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                        </template>

                        <v-list>
                        <v-list-item v-for="n in 4" :key="n" @click="() => {}">
                            <v-list-item-title>Option {{ n }}</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                    </div>
                </div>
                <v-card-text class="pt-0" v-if="heatmapData">
                    <apexchart
                        type="heatmap"
                        width="100%"
                        height="365"
                        :options="heatmapData.chartOptions"
                        :series="heatmapData.series"                                        
                    />
                </v-card-text>
            </base-card> -->

            <base-card class="mt-3" v-if="isDevUser && selected.length>0">
                <v-card-title class="pb-0">Selected Adpoint Timeline(s) shown for {{heatmapDay}}, {{heatmapHour}} (1-hour window)</v-card-title>
                <v-card-text>

                    <v-data-iterator
                        :items="items"
                        :items-per-page.sync="itemsPerPage"
                        :page="page"
                        hide-default-footer
                    >
                        <template v-slot:default="props">
                            <div style="display: flex;">  
                                <div
                                    v-for="(item, index) in props.items"
                                    :key="index"
                                >
                                    <div style="width: 140px; padding: 15px;"><span style="word-wrap: break-word;">{{item.adpointid}}</span></div>
                                    <v-timeline                                        
                                        style="flex: 1;"
                                        :dense="true"
                                        :clipped="true"
                                    >
                                        <v-timeline-item                                                                
                                            fill-dot
                                            color="#DDDDDD"
                                            v-for="(n, indexb) in item.data"
                                            :key="n.timestamp"
                                        >
                                            <template v-slot:icon v-if="n.campaignid">      
                                                <v-tooltip
                                                    v-model="show"
                                                    top
                                                    >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                            <!-- <img src="https://i.pravatar.cc/64"> -->
                                                            <img :src="n.thumbnailUrl">
                                                        </v-avatar>
                                                    </template>
                                                    <!-- <span>Programmatic tooltip</span> -->
                                                    <v-list>
                                                        <v-list-item>
                                                            <v-list-item-title >Timestamp: {{n.timestamp}}</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item-title >
                                                                <h5>
                                                                    {{ n.campaignid }}                                   
                                                                </h5> 
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item-title >
                                                                <h5>
                                                                    Delivery Rule #{{ n.ruleindex }}                                   
                                                                </h5> 
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item-title >
                                                                <img :src="n.thumbnailUrl" alt="" width="60"/>
                                                                <br/>
                                                                <v-chip                                                                                                                                    
                                                                    color="primary"
                                                                    small                                                                
                                                                >
                                                                    {{n.type}}
                                                                </v-chip>
                                                                <!-- <v-chip     
                                                                    v-if="n.isExternal"                                                                                                                               
                                                                    color="secondary"
                                                                    small                                                                
                                                                >
                                                                    external
                                                                </v-chip> -->
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item-title >Cost: {{n.cost}}</v-list-item-title>
                                                        </v-list-item>                                                        
                                                    </v-list>
                                                </v-tooltip> 



                                                <!-- <v-avatar>
                                                    <img src="https://i.pravatar.cc/64">
                                                </v-avatar> -->
                                            </template>
                                            <!-- <template v-slot:opposite>
                                                <span style="min-width: 130px;">Tus eu perfecto</span>
                                            </template> -->
                                            <!-- <v-card class="elevation-2">
                                                <v-card-title class="text-h5">
                                                Lorem ipsum
                                                </v-card-title>
                                                <v-card-text>Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.</v-card-text>
                                            </v-card> -->
                                        </v-timeline-item>
                                    </v-timeline>
                                </div>                                                              
                            </div>
                                <!-- <v-col
                                    v-for="(item, index) in props.items"
                                    :key="index"
                                    cols="12"
                                    md="6"
                                    sm="6"
                                    lg="4"
                                >
                                    <list-card-three
                                        :avatar="item.img"
                                        :title="item.title"
                                        :sub-title="item.subTitle"
                                        footer-btn-color="primary"
                                        footer-btn-color-two="primary"
                                    />
                                </v-col> -->                            
                        </template>
                        <template v-slot:header>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-spacer />

                                <span class="mr-4 grey--text"
                                    >Page {{ page }} of {{ numberOfPages }}</span
                                >
                                <v-btn
                                    small
                                    fab
                                    dark
                                    color="blue darken-3"
                                    class="mr-1"
                                    @click="formerPage"
                                >
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    fab
                                    dark
                                    color="blue darken-3"
                                    class="ml-1 mr-4"
                                    @click="nextPage"
                                >
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-row>
                        </template>
                    </v-data-iterator>                  
                </v-card-text>
            </base-card>

        </v-col>
        <v-col cols="12">
            
        </v-col>
    </v-row>    
</template>

<script>
import api from "src/api/index";
import moment from "moment";
import AdvancedGridFiltersListNew from "@/components/core/AdvancedGridFiltersListNew"
import { integerFormatting } from '@/core/number-utils.js'

import analyticOneCard from "@/components/card/AnalyticCardVersionOne";
import analyticTwoCard from "@/components/card/AnalyticCardVersionTwo";
import {
  analyticOne,
  analyticTwo,
  analyticThree,
  analyticFour,
  analyticFive,
} from "@/data/analytic2";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Adpoints Dashboard'
    },    
    components: {
        "advanced-grid-filters-list": AdvancedGridFiltersListNew        
    },
    data() {
        return {
            analyticOne,
            analyticTwo,
            analyticThree,
            analyticFour,
            //analyticFive,
            // gridConfig: {
            //     rowActions: [
            //         {
            //             title: "Edit",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //         {
            //             title: "Delete",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //     ],
            //     columns: [
            //         {title: "Name", 
            //         type: "text", 
            //         bindings: {
            //             datafield: "fullname",
            //             prependIcon: "",
            //             appendIcon: "",
            //             formatter: (rowData) => {
            //                 return v;
            //             },
            //             statusMapper: (rowData) => {
            //                 return {
            //                     color: "",
            //                     icon: "",
            //                     text: ""
            //                 }
            //             },

            //         }}
            //     ]
            // },
            selectedNetwork: null,
            networkOptions: [],
            attributeOptions: [],
            brandOptions: [],
            deviceTypeOptions: [],
            selectedCalcInterval: 'Last 7 Days',
            calculationDate: [],
            calculationDatepicker: null,
            attributeToAdd: null,
            heatmapDay: null,
            heatmapHour: null,
            heatmapData: null,
            isDevUser: ( localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo")).user && (JSON.parse(localStorage.getItem("userInfo")).user.role === "dev")),





            series: [100],
            chartOptions: {
                chart: {
                height: 120,
                type: "radialBar"
                },
                
            
                colors:["#33cc33"],
                plotOptions: {
                radialBar: {
                    hollow: {
                    margin: 0,
                    size: "40%"
                    },
                
                    dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: false,
                        color: "green",
                        fontSize: "13px"
                    },
                    value: {
                        offsetY: 6,
                        color: "#828d99",
                        fontSize: "15px",
                        show: true,
                        fontWeight: 700,
                    }
                    }
                }
                },
            
                stroke: {
                lineCap: "round",
                },
                labels: ["Progress"]
            },  
            
            series2: [24],
            chartOptions2: {
                chart: {
                height: 120,
                type: "radialBar"
                },
                
            
                colors:["#ff5c33"],
                plotOptions: {
                radialBar: {
                    hollow: {
                    margin: 0,
                    size: "40%"
                    },
                
                    dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: false,
                        color: "green",
                        fontSize: "13px"
                    },
                    value: {
                        offsetY: 6,
                        color: "#828d99",
                        fontSize: "15px",
                        show: true,
                        fontWeight: 700,
                    }
                    }
                }
                },
            
                stroke: {
                lineCap: "round",
                },
                labels: ["Progress"]
            },



            totalItemCount: 0,
            quicksearch: '',
            pagingOptions: {},
            selected: [],
            baseData: [],
            select_field: null,
            select_operator: null,                        
            filterfields: [],                    
            filtersVisible: true,
            headers: [
                {text: 'Adpoint ID', align: 'start', value: 'adpointid',  sortable: false},
                // {text: 'Network', align: 'start', value: 'network',  sortable: false},                
                {text: 'Device Type', value: 'deviceId',  sortable: false},
                // {text: 'Capacity', value: 'nominalCapacity'},
                // {text: '', value: 'usedCapacity'},
                // {text: 'Products', value: 'fat'},
                // {text: 'Wallet Balance', value: 'carbs'},
                {text: 'Status', value: 'status',  sortable: false},
                {text: 'Create Date', value: 'aud_create_date'},                
                {text: 'Last Update', value: 'aud_update_date'},
                {text: '', value: 'action'}                
            ],

            page: 1,
            itemsPerPage: 9,
            items: [
                // {
                //     img: require('@/assets/images/avatars/001-man.svg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/002-woman.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/003-man-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/007-woman-2.svg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/006-woman-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/003-man-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/faces/1.jpg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/faces/2.jpg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/faces/3.jpg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/001-man.svg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/002-woman.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/003-man-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/007-woman-2.svg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/006-woman-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/003-man-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/001-man.svg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/002-woman.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/003-man-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/007-woman-2.svg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/006-woman-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/avatars/003-man-1.svg'),
                //     title: 'Zac Clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // },
                // {
                //     img: require('@/assets/images/faces/1.jpg'),
                //     title: 'Timothy clarkson',
                //     subTitle:
                //         'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                // }
            ],
        }
    },
    filters: {
        formatDate(value) {
            return moment.utc(value).format("DD/MM/YYYY");
        }
    },
    watch: {
      pagingOptions: {
        handler () {
            if(this.selectedNetwork) {
                this.fetchGridData();
            }            
        },
        deep: true,
      },
      selected(val) {
        this.generateHeatmapData();
        this.items.length=0;
        val.forEach(t=> { this.items.push({adpointid: t.adpointid, data: this.generateTimelineData()}) })
      },
      heatmapDay() {    
        this.items.length=0;            
        this.selected.forEach(t=> { this.items.push({adpointid: t.adpointid, data: this.generateTimelineData()}) })
      },
      heatmapHour() {   
        this.items.length=0;             
        this.selected.forEach(t=> { this.items.push({adpointid: t.adpointid, data: this.generateTimelineData()}) })
      },
    //   quicksearch: function() {
    //       this.fetchGridData();
    //   }
    },
    computed: {
        gridData() {
            const rspData = this.baseData;
            return this.formatListData(rspData);
        },
        numberOfPages() {
            return Math.ceil(this.selected.length / this.itemsPerPage)
            //return Math.ceil(this.items.length / this.itemsPerPage)            
        },
        filterColumnCount() {
            const d = this.filtersVisible;
            return (d ? 2 : 0);
        },
        gridColumnCount() {
            const d = this.filtersVisible;
            return (d ? 10 : 12);
        },
    },
    methods: {        
        formatInteger(src) {
            return integerFormatting(src);
        },
        onDeleteCondition({index, nameToDelete}) {            
            //alert("COMP: " + nameToDelete + "\n\n filterfields: " + JSON.stringify(this.filterfields));
            //alert("COMP: " + nameToDelete + "\n\n filterfields: " + this.filterfields.length);
            //this.filterfields = [...this.filterfields.filter(f=>f.value != nameToDelete)];
            //this.items.splice(this.items.indexOf(item), 1);
            const itemToDelete = this.filterfields[index].find(f=>f.value == nameToDelete);
            if(itemToDelete) {
                this.filterfields[index].splice(this.filterfields[index].indexOf(itemToDelete), 1);
            }                        
        },
        onDeleteOrCondition(index) {
            this.filterfields.splice(index, 1);
        },
        addCondition({attributeToAdd, orClauseIndex}) {            
            const selectedAttributeData = this.attributeOptions.find(o=>o.value==attributeToAdd);
            if(!selectedAttributeData) 
                return;            
            this.filterfields[orClauseIndex].push({
                value: selectedAttributeData.extra.name,
                text: selectedAttributeData.extra.description || selectedAttributeData.extra.name,
                criteria: {},
                // type: selectedAttributeData.extra.type.startsWith('tag') ? "list" : "text",
                // type: selectedAttributeData.extra.type.startsWith('tag') ? "list" : selectedAttributeData.extra.type,
                type: selectedAttributeData.extra.type,
                listItems: selectedAttributeData.extra.valuelist.map(i=> {
                    if(!i.hasOwnProperty('value')) { 
                        return {value: i, text: i};
                    } 
                    else {
                        return i;
                    } 
                })
            });  
            //this.attributeToAdd = null;          
        },
        addOrClause() {
            this.filterfields.push([]);
        },
        getOptionDescription(optionList, optionValue){
            let matchedItem = optionList.find(o=>o.value===optionValue);
            let rslt = optionValue
            if(matchedItem) {
                rslt = matchedItem.text;
            }
            return rslt;
        },
        formatListData(rspData) {
            const d = rspData.map(d => { 
                return {
                    ...d, 
                    fullname: d.firstName + ' ' + d.lastName,                    
                } })
            return d;
        },
        async fetchGridData() {            
            let qstr = "";
            //paging
            console.log("\nPAGING PAGING PAGING PAGING \n" + JSON.stringify(this.pagingOptions));
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagingOptions;
            const limit = itemsPerPage;
            const currentPage = page;
            qstr += "?currentPage=" + page + "&limit=" + limit;
            //sorting       
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            for(let i=0; i<sortBy.length; i++) {                
                qstr += "&sortBy[" + i + "]=" + sortBy[i];                
            }
            //sorting                   
            for(let i=0; i<sortDesc.length; i++) {                
                qstr += "&sortDesc[" + i + "]=" + sortDesc[i];                
            }     
            //criteria - selected network & duration
            qstr += "&networkId=" + this.selectedNetwork;
            qstr += "&calcInterval=" + this.selectedCalcInterval;
            //criteria - advanced filters
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            if(this.$refs.advancedfilter.qs) {                
                qstr += this.$refs.advancedfilter.qs;
            }
            //quicksearch
            if(this.quicksearch && this.quicksearch.length > 0){
                //if(!qstr.includes("?")) qstr = "?" + qstr;
                qstr += "&quicksearch=" + encodeURIComponent(this.quicksearch);
            }      
            //alert(querystr);
            const rsp = await api.adpoint.getList(qstr);
            this.baseData = rsp.data.data.map(d => { return {...d, 
                                                        //fullname: d.firstName + ' ' + d.lastName
                                                    } }); 
            this.totalItemCount = rsp.data.total;
        },
        // toggleFilterView() {
        //     this.filtersVisible = ! this.filtersVisible
        // },
        onSearch() {
            //alert(JSON.stringify(this.filtercriteria, null, 2));
            //alert(JSON.stringify(this.filtercriteria.qs, null, 2));
            //alert(JSON.stringify(this.$refs.advancedfilter.criteria, null, 2));
            //alert(JSON.stringify(this.$refs.advancedfilter.qs, null, 2));
            this.fetchGridData();
        },
        triggerQuicksearch() {
            // if (this.quicksearch && this.quicksearch.length > 2) {
            //     this.fetchGridData();
            // }      
            this.fetchGridData();       
        },

        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        // updateItemsPerPage(number) {
        //     this.itemsPerPage = number
        // },
        getTimelineData(selectedAdp) {
            let rslt = [

            ];

        },
        onHeatmapClick(event, chartContext, config) {
            var el = event.target;
            var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            //alert(JSON.stringify({seriesIndex, dataPointIndex}));

            
            let day = this.heatmapData.series[seriesIndex].name;
            let time = this.heatmapData.chartOptions.xaxis.categories[dataPointIndex];
            this.heatmapDay = day;
            this.heatmapHour = time;
            //alert(JSON.stringify({day,time}));
        },
        generateHeatmapData(selectedAdp) {
            function generateData(count, yrange) {
                var i = 0
                var series = []
                while (i < count) {
                    var x = (i + 1).toString()
                    var y = 0; //Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

                    series.push({
                    x: x,
                    y: y,
                    })
                    i++
                }
                return series
            };

            let analyticFive = {
                series: [
                    {
                    name: 'Mon',
                    data: generateData(12, {
                        min: 0,
                        max: 90,
                    }),
                    },
                    {
                    name: 'Tue',
                    data: generateData(12, {
                        min: 0,
                        max: 90,
                    }),
                    },
                    {
                    name: 'Wed',
                    data: generateData(12, {
                        min: 0,
                        max: 90,
                    }),
                    },
                    {
                    name: 'Thu',
                    data: generateData(12, {
                        min: 0,
                        max: 90,
                    }),
                    },
                    {
                    name: 'Fri',
                    data: generateData(12, {
                        min: 0,
                        max: 90,
                    }),
                    },
                    {
                    name: 'Sat',
                    data: generateData(12, {
                        min: 62,
                        max: 95,
                    }),
                    },
                    {
                    name: 'Sun',
                    data: generateData(12, {
                        min: 0,
                        max: 0,
                    }),
                    },
                ],
                chartOptions: {                    
                    chart: {
                        height: 350,
                        type: 'heatmap',
                        toolbar: {
                            show: false,
                        },
                        events: {
                            dataPointSelection: this.onHeatmapClick
                            
                            
                            // function(event, chartContext, config) {
                            //     var el = event.target;
                            //     var seriesIndex = parseInt(el.getAttribute("i"));
                            //     var dataPointIndex = parseInt(el.getAttribute("j"));
                            //     alert(JSON.stringify({seriesIndex, dataPointIndex}));
                            //     // alert(JSON.stringify({event, chartContext, config}));
                            //     //alert('a');
                            // }
                            ,
                            click: function(event, chartContext, config) {
                                //alert(JSON.stringify({event, chartContext, config}));
                                //alert('b');
                            },
                            selection: function(event, chartContext, config) {
                                //alert(JSON.stringify({event, chartContext, config}));
                                //alert('c');
                            },
                        },
                    },
                    grid: {
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    },
                    plotOptions: {
                    heatmap: {
                        radius: 10,
                    },
                    },
                    dataLabels: {
                    enabled: false,
                    },
                    colors: ['#008FFB'],
                    xaxis: {
                    categories: [
                        // '12am',
                        // '1am',
                        // '3am',
                        // '4am',
                        // '5am',
                        // '6am',
                        // '7am',
                        // '8am',
                        '9am',
                        '10am',
                        '11am',
                        '12pm',
                        '1pm',
                        '2pm',
                        '3pm',
                        '4pm',
                        '5pm',
                        '6pm',
                        '7pm',
                        '8pm',
                        '9pm',
                        // '10pm',
                        // '11pm',
                    ],
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    },
                },

                }
            this.heatmapData=analyticFive;
        },
        generateTimelineData() {
            let rslt = [];
            let baseData = [
                {                    
                    timestamp: '',
                    campaignid: '10-220703-P11915-BG-TVS-TVC-SAMSUNG_TVS_SUP-homepage', //10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage
                    ruleindex: 1,
                    isExternal: false,
                    type: 'image',
                    assetUrl: 'https://adpointsblob.blob.core.windows.net/assets/0%2FBOOKSCapture.PNG',
                    thumbnailUrl: 'https://adpointsblob.blob.core.windows.net/assets/0%2FBOOKSCapture_thumb.PNG',
                    cost: 1                    
                },
                {                    
                    timestamp: '',
                    campaignid: '10-220703-P11915-BG-TVS-TVC-SAMSUNG_TVS_SUP-homepage', //
                    ruleindex: 2,
                    isExternal: false,
                    type: 'image',
                    assetUrl: 'https://adpointsblob.blob.core.windows.net/assets/0%2FJS-Startpoint-Logo.PNG',
                    thumbnailUrl: 'https://adpointsblob.blob.core.windows.net/assets/0%2FJS-Startpoint-Logo_thumb.PNG',
                    cost: 1                    
                },
                {                    
                    timestamp: '',
                    campaignid: '10-220703-P11915-BG-TVS-TVC-SAMSUNG_TVS_SUP-homepage', //
                    ruleindex: 3,
                    isExternal: false,
                    type: 'htmlbundle',
                    assetUrl: 'https://adpointsblob.blob.core.windows.net/assets/0/vecteezy_zip-flat-multicolor-icon_8240633_202.zip',
                    thumbnailUrl: 'https://adpointsblob.blob.core.windows.net/assets/HtmlZip_Icon_60x60.jpg',
                    cost: 1                    
                },
                {                    
                    timestamp: '',
                    campaignid: '10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage', //10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage
                    ruleindex: 1,
                    isExternal: false,
                    type: 'video',
                    assetUrl: 'https://adpointsblob.blob.core.windows.net/assets/0%2FBOOKSCapture.PNG',
                    thumbnailUrl: 'https://adpointsblob.blob.core.windows.net/assets/0%2FBOOKSCapture_thumb.PNG',
                    cost: 1                    
                },
                {                    
                    timestamp: '',
                    campaignid: '10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage', //10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage
                    ruleindex: 2,
                    isExternal: true,
                    type: 'image',
                    assetUrl: 'https://adpointsblob.blob.core.windows.net/assets/0/Boy_Looking_HelicopterToy.png',
                    thumbnailUrl: 'https://adpointsblob.blob.core.windows.net/assets/External_60x60.jpg',
                    cost: 1                    
                },
                {                    
                    timestamp: '',
                    campaignid: '', //10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage
                    ruleindex: 0,
                    isExternal: false,
                    type: '',
                    assetUrl: '',
                    thumbnailUrl: '',
                    cost: 0                    
                },
                {                    
                    timestamp: '',
                    campaignid: '', //10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage
                    ruleindex: 0,
                    isExternal: false,
                    type: '',
                    assetUrl: '',
                    thumbnailUrl: '',
                    cost: 0                    
                },
            ];
            if(this.heatmapHour && this.heatmapDay)
            for(let i=0; i<240; i++) {
                const ImpressionInterval = 15;
                let parsedHour = parseInt(this.heatmapHour.substring(0,2));
                if(this.heatmapHour.endsWith('pm')) {
                    parsedHour+=12;
                }
                let secondsOffset = i*ImpressionInterval;
                let timestamp = parsedHour + 'h ' + Math.floor(secondsOffset/60) + 'm ' + (secondsOffset%60) + 's';
                //return Math.floor(Math.random() * (max - min + 1) + min)
                rslt.push({
                    ...baseData[Math.min(Math.floor(Math.random() * 7), 6)],
                    timestamp                
                });                    
            }                   
            return rslt;
        }
    },
    async created() {
        const rsp = await api.adpoint.getInitData();
        this.networkOptions = rsp.data.networkOptions;
        this.deviceTypeOptions = rsp.data.deviceTypeOptions;
        this.brandOptions = rsp.data.brandOptions;
        this.attributeOptions = [...rsp.data.attributeOptions.map(i=> { return {...i, text: '['+i.value+'] ' + i.text}}), ...rsp.data.standardAttributeOptions.map(i=> { return {...i, text: '['+i.value+'] ' + i.text}})];
        //alert(JSON.stringify(rsp.data.standardAttributeOptions.map(i=> { return {...i, text: '['+i.value+'] ' + i.text}})));
        if(this.networkOptions.length>0){
            if(this.networkOptions.find(n=>n.value===1)) {
                this.selectedNetwork = 1;
            }
            else {
                this.selectedNetwork = this.networkOptions[0].value;
            } 
        }    
                
        this.filterfields.push([]);  
        this.addCondition({attributeToAdd: "brandId", orClauseIndex: 0});
        this.addCondition({attributeToAdd: "deviceId", orClauseIndex: 0});
        // this.filterfields[0].push(
        //     {
        //         value: "brandId",
        //         text: "Brand",
        //         criteria: {},
        //         type: "list",
        //         listItems: this.brandOptions
        //     }
        // );

        // this.filterfields[0].push(
        //     {
        //         value: "deviceId",
        //         text: "Device Type",
        //         criteria: {},
        //         type: "list",
        //         listItems: this.deviceTypeOptions
        //     },
        // );    

        this.fetchGridData();
        // const rsp = await api.users.getList("");
        // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
        // this.baseData = [{
        //             img: require('@/assets/images/avatars/001-man.svg'),
        //             name: 'Jhon Doe',
        //             calories: 'Walmart',
        //             fat: '95',
        //             carbs: '$14,384',
        //             protein: '03/24/2020',
        //             badge: 'Active'
        //         },
        //         {
        //             img: require('@/assets/images/avatars/002-woman.svg'),
        //             name: 'Elizabeth Doe',
        //             calories: 'KFC',
        //             fat: '876',
        //             carbs: '$38,384',
        //             protein: '03/24/2020',
        //             badge: 'Deactive'
        //         },]
        //console.log("DBGINDICATOR_ " + JSON.stringify(XYZ, null, ''));
    },
    async mounted() {
        let ff = this.filterfields.find(i=>i.selectvalue === "brand");        
        if(ff) {
            const rsp = await api.brand.getAll();            
            ff.listItems = rsp.data.map(i=> {return {selectvalue: i.code, selecttext: i.title}});
        }
        this.generateHeatmapData();
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
